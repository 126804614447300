@import '../../assets/variables.module.scss';

.header{
    background-color: $color-dark;
    color: white;
    height: 70px;

    .logo{
        display: inline-block;
        height: 100%;
        background: transparent url('../../assets/images/sgts-logo.svg') left center no-repeat;
        padding: 0px 40px 0px 50px;
        margin: 15px 0px;
        line-height: 40px;
        font-size: 20px;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    .navigation-menu{
        display: inline-block;
    }

    .navigation-user-menu{
        float: right;
    }
}