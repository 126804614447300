@import '../../assets/variables.module.scss';

.zol-table, dl.user-details{
    .personal-key, .master-key{
        > svg{
            display: inline-block;
            vertical-align: middle;
            color: $color-yellow;
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
    .master-key > svg{
        color: $color-light;
    }
    .master-key-active > svg{
        color: $color-green;
    }
}