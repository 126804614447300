@import "../../../index.scss";

.zol-modal-overlay{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    overflow-y: auto;
    z-index: 5;

    .zol-modal{
        position: relative;
        max-height: calc(100vh - 50px);
        width: 500px;
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

        .zol-modal-header{
            padding: 10px 30px;
            position: sticky;
            left: 0px;
            height: 50px; /* needs to be fixed in order for the content height to work */
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close-icon{
                fill: #2E384D;
                width: 30px;
                height: 30px;
                cursor: pointer;
            }
        }

        .zol-modal-content{
            /* minus height header (70px) and margin zol-modal (50px) and padding content (70px) */
            max-height: calc(100% - 70px); 
            padding: 0px 30px 0px;
            overflow-y: auto;
        }
    }
}