@import '../../assets/variables.module.scss';

a.navigation-link, a.navigation-link:visited, a.navigation-link:active{
    color: $color-blue;
    font-weight: 400;
    opacity: 1;
    transition: opacity 0.2s;

    &:hover{
        opacity: 0.8;
    }

    > svg{
        width: 20px;
        fill: $color-blue;
        vertical-align: middle;
    }
}

.navigation-back{
    fill: $color-blue;
    text-decoration: none;
    font-size: 110%;
    font-weight: 400;
    float: right;
    line-height: 20px;

    > svg{
        vertical-align: top;
        width: 20px;
    }
}

.navigation-item-edit{
    float: right;

    > a.navigation-link{
        text-decoration: none;
        margin-left: 20px;
    }
}