@import '../../assets/variables.module.scss';

.recovery-word{
    display: inline-block;
    margin: 3px;
    padding: 2px 10px;
    font-size: 18px;
    font-weight: bold;
    color: $color-dark;
    border: 1px solid $color-light;
}

.wallet-total-usd > span{
    float: right;
    font-weight: bold;
}