@import '../../assets/variables.module.scss';

.navigation-user-menu{
    position: relative;
    margin: 15px 0px;
    padding: 0px 0px 0px 40px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;

    .user-menu-photo{
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        overflow: hidden;
        line-height: 0px;
        transition: border-color 0.2s;
        fill: white;
        opacity: 0.8;

        > svg{
            width: 40px;
            height: 40px;
        }
    }

    .user-menu-name{
        display: inline-block;
        vertical-align: middle;
        line-height: 40px;
        margin: 0px 20px;
        opacity: 0.85;
        transition: opacity 0.2s;
    }

    .user-menu-toggle{
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        fill: $color-white;

        > svg{
            width: 20px;
            transition: transform 0.2s;
        }

        &.active{
            > svg{
                transform: rotate(180deg);
            }
        }
    }

    &:hover{
        .user-menu-photo{
            border-color: $color-white;
        }
        .user-menu-name{
            opacity: 1;
        }
    }

    .navigation-user-menu-dropdown{
        position: absolute;
        background: $color-dark;
        top: 100%;
        margin: 0px;
        padding: 20px;
        left: 20px;
        right: -20px;
        list-style: none;
        z-index: 2;

        > li{
            padding: 0px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            &:last-child{
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }

            > a{
                display: block;
                padding: 5px 0px 8px;
                color: white;
                text-decoration: none;

                > svg{
                    fill: white;
                    width: 20px;
                    vertical-align: middle;
                    margin-right: 5px;
                }
            }
        }
    }
}