@import '../../../assets/variables.module.scss';

.zol-tag{
    display: inline-block;
    padding: 2px 10px;
    margin: 0 8px 0 0;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #fafafa;
    color: rgba(0,0,0,.85);
}

.zol-custom{
    color: #fff;
}

.zol-color-green{
    color: #38CA0C;
    background: #e2ffda;
    border: 1px solid #38CA0C;
}
.zol-color-red{
    color: #B54A82;
    background: #ffd7ec;
    border: 1px solid #B54A82;
}
.zol-color-orange{
    color: #F6CD4F;
    background: #FEF3D2;
    border: 1px solid #F6CD4F;
}

.zol-tag-copy{
    font-weight: 600;
    > svg{
      display: inline-block;
      width: 20px;
      fill: $color-black;
      margin-left: 5px;
      vertical-align: middle;
      opacity: 0.6;
      transition: opacity 0.2s;
      cursor: pointer;

      &:hover{ opacity: 1;}
    }
}

span.zol-tag-status{
    position: relative;
    float: right;
    padding: 5px 15px;
    background-color: $color-light;
    border: 1px solid $color-light;
    color: $color-dark;
    border-radius: 7px;
    overflow: hidden;

    em{
        position: relative;
        font-style: normal;
    }

    &:before{
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(255, 255, 255, 0.7);
    }

    &.status-created{
        background-color: $color-yellow;
        border-color: $color-yellow;
    }

    &.status-approved{
        background-color: $color-blue;
        border-color: $color-blue;
    }

    &.status-sgts_approved, &.status-executing, &.status-executed{
        background-color: $color-green;
        border-color: $color-green;
    }

    &.status-rejected{
        background-color: $color-orange;
        border-color: $color-orange;
    }
}

.zol-tag-transaction{
    position: relative;
    padding: 0px 10%;

    .zol-tag-transaction-arrow{
        position: absolute;
        left: 0px;
        right: 0px;
        top: calc(50% - 1px);
        height: 2px;
        background-color: $color-orange;
    }

    .zol-tag-transaction-amount{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        border: 2px solid $color-orange;
        background-color: white;
        text-align: center;
        text-transform: uppercase;
    }

    &.zol-tag-transaction-out{
        margin-right: 20px;
        .zol-tag-transaction-arrow:after{
            position: absolute;
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            border-color: transparent;
            border-left-color: $color-orange;
            border-width: 8px;
            margin-top: -8px;
        }
    }
}