@import '../../../assets/variables.module.scss';

.key-box, .key-box-large{
    display: inline-block;
    max-width: 200px;
    vertical-align: top;

    > strong{
        display: block;
    }

    .zol-tag-copy{
        display: block;
        margin: 20px 5px;
        padding: 10px 20px;
        border: 1px solid $color-light;
    }

}

.key-box-large{
    max-width: 500px;
}