@import '../../assets/variables.module.scss';

.navigation-breadcrumb{
    fill: $color-dark;

    a.navigation-link, a.navigation-link:visited, a.navigation-link:active{
        color: $color-dark;
        font-weight: bold;
        text-decoration: none;
    }

    > svg {
        width: 20px;
        margin: 0px 10px;
    }
}