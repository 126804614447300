@import '../../assets/variables.module.scss';

.wallet-balance{
    float: right;
    font-size: 90%;
}

.transaction-details{
    background-color: $color-off-white;
    padding: 20px;

    .zol-tag-transaction{
        margin-top: 10px;
    }

    a.navigation-link{
        color: $color-dark;
        text-decoration: none;
        font-weight: bold;
    }

    .transaction-details-balance{
        font-size: 80%;
    }
}

.zol-modal-content .transaction-details{
    background-color: transparent;
    margin-bottom: 20px;
    padding-top: 0px;
    border: 1px solid $color-light;
}

.transaction-amount{
    position: relative;
    .ui-input-item{
        &:first-child .ui-input-field{
            width: calc(50% - 100px);
            max-width: 300px;
        }
        &:last-child{
            .ui-input-label{
                display: none;
            }
            .ui-input-field{
                position: absolute;
                top: 8px;
                left: 510px;
                width: 90px;
            }
        }
    }
}