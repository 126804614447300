/* Global project variables file. Here we can define some general variables */

$color-white: #fff;
$color-off-white: #F6F8FA;
$color-light: #E5E5E5;
$color-medium: #9d9d9d;
$color-dark: #262D50;
$color-black: #000;

$color-primary: #00CFA6;
$color-green: #00CFA6;
$color-yellow: #FFE600;
$color-orange: #FF9900;
$color-blue: #15ABE4;

:export {
  colorWhite: $color-white;
  colorOffWhite: $color-off-white;
  colorLight: $color-light;
  colorMedium: $color-medium;
  colorDark: $color-dark;
  colorPrimary: $color-primary;
  colorBlue: $color-blue;
  colorYellow: $color-yellow;
  colorGreen: $color-green;
  colorOrange: $color-orange;
}