@import '../../assets/variables.module.scss';

.notification{
    margin: 20px 0px;
    border: 4px solid $color-orange;

    h2, p{
        margin: 0px 0px 5px;
    }

    .notification-icon{
        float: left;
        width: 20%;
        max-width: 70px;
        fill: $color-dark;
        margin-right: 20px;

        > svg{
            width: 100%;
        }
    }

    .zol-ui-button{
        float: right;
        margin-left: 20px;
    }

    &:after{
        content: '';
        display: block;
        clear: both;
    }
}