@import '../../assets/variables.module.scss';

.zol-view-panel{
    background-color: $color-white;
    border: 1px solid $color-light;
    padding: 30px 35px;

    &.panel-table{
        padding: 0px;
        margin-top: 50px;

        > h2{
            font-size: 18px;
            font-weight: 500;
            margin: 0px;
            padding: 0px 15px;
            line-height: 60px;
        }

        > div{
            padding: 15px;
            &.zol-table-container{
                padding: 0px;
            }
        }

        .zol-table{
            margin-top: 0px;
        }

        .action{
            float: right;
            margin-right: 15px;
            line-height: 50px;
        }
    }
}