@import '../../../assets/variables.module.scss';

.zol-ui-button{
    display: inline-block;
    padding: 6px 15px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: $color-primary;
    color: white;
    font-weight: 400;
}

.zol-clear {
    background-color: transparent;
    color: $color-primary;
}
.zol-ui-button.zol-light{
    background-color: $color-light;
    color: $color-black;
}

.zol-ui-button-icon{

    svg{
        width: 25px;
        height: 25px;
        fill: white;
        vertical-align: middle;
        margin: 0px 15px 0px -5px;
    }
}

.zol-ui-button-reject{
    background-color: $color-orange;
}
.zol-ui-button-cancel{
    background-color: $color-medium;
}

.btn-right{
    float: right;
}
.btn-left{
    float: left;
}

.zol-ui-botton-disabled{
    cursor: not-allowed;
    background-color: $color-medium;
}