@import '../../assets/variables.module.scss';

.workflow{
    margin: 10px 0px;
    
    > div{
        position: relative;
        padding: 15px 0px 5px 30px;
        min-height: 30px;

        h4{
            font-weight: 300;
            font-size: 90%;
            margin: 0px 0px 0px;
        }
        &.is-active h4{
            font-weight: 400;
        }
        h3{
            font-size: 100%;
            margin: 0px;
        }
        >span{
            display: block;
            font-size: 90%;
            font-style: italic;
        }
        a {
            font-size: 110%;
            font-weight: bold;
            text-decoration: none;
            display: block;
            margin: 5px 0px;
        }

        &:before{
            content: '';
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 6px;
            width: 3px;
            background-color: $color-light;
        }

        &:after{
            content: '';
            position: absolute;
            top: calc(50% - 6px);
            left: 0px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 2px solid white;
            background-color: $color-light;
        }

        &.is-active-before:not(.is-active){
            span:after{
                content: 'Action pending';
                display: block;
                padding: 0px 0px 0px 22px;
                background: transparent url('../../assets/icons/icon-action-pending.svg') left center no-repeat;
                background-size: 20px;
                color: $color-orange;
                font-size: 80%;
                line-height: 26px;
            }
        }

        &.workflow-step1{
            &:before{
                top: 50%;
            }
            &.is-active-after:before{
                background-color: $color-medium;
            }
            &.is-active:after{
                background-color: $color-yellow;
            }
        }

        &.workflow-step2{
            &.is-active{
                &:before{
                    background: linear-gradient(180deg, $color-medium 48%, $color-light 52%);
                }
                &:after{
                    background-color: $color-blue;
                }
                &.is-active-after:before{
                    background: $color-medium;
                }
            }
        }

        &.workflow-step3{
            &:before{
                bottom: 50%;
            }
            &.is-active{
                &:before{
                    background-color: $color-medium
                }
                &:after{
                    background-color: $color-green;
                }
            }
        }
    }

    &.workflow-transaction > div{
        &.workflow-step3{
            &:before{
                bottom: 0px;
            }
        }
        &.workflow-step4{
            &:before{
                bottom: 50%;
            }
            &.is-active{
                &:before{
                    background-color: $color-medium
                }
                &:after{
                    background-color: $color-green;
                }
            }
        }
    }
}