@import '../../../assets/variables.module.scss';

.ui-input-item{
    padding: 8px 0px;

    .ui-input-label{
        display: inline-block;
        width: 50%;
        max-width: 200px;
        vertical-align: top;
    }
    .ui-input-field{
        display: inline-block;
        width: 50%;
        max-width: 400px;

        .warning{
            display: block;
            padding: 10px 0px 5px 50px;
            background: transparent url('../../../assets/icons/alert-triangle-outline.svg') 5px 15px no-repeat;
            background-size: 34px;
        }
    }
}

.zol-single-input {
    width: 100%;
    max-width: 400px;
    height: 30px;
    line-height: 30px;
    border: 1px solid $color-light;
    border-radius: 4px;
    padding: 0px 5px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    box-sizing: border-box;
}

// select element style
.zol-single-select{
    display: block;
    width: 100%;
    outline: none;
    line-height: 30px;
    height: 30px;
    background-color: $color-white;
    border: 1px solid $color-light;
    border-radius: 4px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
}

textarea.zol-single-input {
    font-family: "Lato", sans-serif;
    min-height: 60px;
}

.btn-submit{
    margin-top: 20px;
    margin-left: 200px;
}
@media only screen and (max-width: 500px) {
    .btn-submit{
        margin-left: 50%;
    }
}