@import './grid.module.scss';

.zol-grid-row{
    position: relative;
    max-width: 1400px;
    margin: 0px auto;

    &.page-row{
        margin-top: 50px;
    }

    .zol-grid-col{
        display: inline-block;
        vertical-align: top;
        margin: 0px calc(#{$column-spacing}px / 2);

    }
}