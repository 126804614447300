@import '../../../assets/variables.module.scss';

.zol-table-scrollable{
    overflow-x: auto;
}
.zol-table{
    position: relative;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $color-light;
    margin-top: 30px;

    .zol-table-header{
        background-color: $color-off-white;
        
        .zol-table-header-item{
            text-align: left;
            font-weight: 700;
            font-size: 90%;
            padding: 20px 0px;
            vertical-align: middle;

            &:first-child{
                padding-left: 15px;
            }
            &:last-child{
                padding-right: 15px;
            }

            .zol-table-header-sort{
                display: inline-flex;
                flex-direction: column;
                margin-left: 5px;
                vertical-align: middle;

                > span > svg{
                    width: 15px;
                    height: 15px;
                }
            }

        }
    }

    .zol-table-body{
        background-color: $color-white;

        td {
            padding: 20px 0;
        }

        .zol-table-row{
            border-top: 1px solid $color-light;
            .zol-table-cell{
                background-color: $color-white;
                text-align: left;
                padding: 20px 0;
                &:first-child{
                    padding-left: 15px;
                }
                &:last-child{
                    padding-right: 15px;
                }
            }
        }

        .zol-table-body-offset, .zol-table-body-offset td{
            padding: 0px;
        }
    }

}
.zol-table-navigation{
    height: 60px;
}