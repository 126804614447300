@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@import './assets/variables.module.scss';

html,
body {
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-black;
    background-color: $color-off-white;
}

.scw{
    padding-bottom: 100px;
}

h1{
    color: $color-dark;
    font-size: 26px;
    font-weight: bold;
}

h2{
    color: #262D50;
    font-size: 18px;
    margin: 0px 0px 15px 0px;
    line-height: 30px;
}

.bolder{ 
    font-weight: 700;
    font-size: 95%;
    opacity: 0.8;
}

.center-align{
    text-align: center;
}

.right-align{
    text-align: right;
}

p.error{
    font-size: 120%;
    font-weight: 400;
    color: $color-orange;
}
p.large-metric{
    font-size: 140%;
    color: $color-dark;
    opacity: 0.9;
    
    > strong{
        font-size: 200%;
        vertical-align: middle;
        margin-right: 20px;
    }
}

dl{
    dt{
        clear: left;
        float: left;
        width: 50%;
        max-width: 200px;
        font-weight: bold;
        font-size: 90%;
        margin: 0px 20px 0px 0px;
        padding: 5px 0px;
    }
    dd{
        display: block;
        width: 100%;
        margin: 0px;
        padding: 5px 0px;
    }
}

.item-status{
    strong{
        display: block;
    }
    .zol-ui-button{
        margin: 5px 0px;
    }
}

.auth-protection-secret{
    background: $color-off-white url('../assets/icons/icon-lock-dark.svg') right top no-repeat;
    background-size: 50px;
    padding: 30px 0px 10px;
    margin-bottom: 20px;
}