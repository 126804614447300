@import '../../assets/variables.module.scss';

.navigation-menu{
    margin: 0px 0px 0px 20px;
    padding: 0px;

    > li{
        display: inline-block;
        line-height: 70px;
        padding: 0px 20px;

        > a.navigation-link{
            color: $color-white;
            text-decoration: none;
            opacity: 0.85;
            transition: opacity 0.2s;

            &.active{
                position: relative;
                font-weight: bold;
                opacity: 1;
                cursor: default;
                
                &:after{
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: '';
                    // height: 0;
                    // width: 0;                   
                    border-color: rgba(255, 255, 255, 0);
                    border-bottom-color: $color-white;
                    border-width: 8px;
                    margin-left: -8px;
                }
            }

            &:hover{
                opacity: 1;
            }
        }
    }
}